/*           Navigation Bar             */
.navbar {
  border: 0 none;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  font-size: 14px;
}
.navbar-default {
  background-color: #d9d9d9;
}
@media (max-width: 768px) {
  .navbar .navbar-brand {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.navbar .navbar-brand {
  font-weight: 600;
  margin-top: 13px;
  margin-bottom: 13px;
  font-size: 20px;
}
.navbar-nav > li > a {
  margin: 6px 3px;
  padding: 6px 7px;
  text-align: center;
  min-width: 70px;
}
.navbar-nav > li > a [class^="fa"] {
  font-size: 18px;
}
.navbar-brand {
  padding: 20px 15px;
}
.navbar .navbar-nav [class^="pe"] {
  font-size: 32px;
  position: relative;
}
.navbar .navbar-nav p {
  margin: 5px 0 0;
  font-size: 14px;
}
.navbar .btn {
  margin-top: 8px;
  margin-bottom: 8px;
}
.navbar-default:not(.navbar-transparent) {
  background-color: rgba(255, 255, 255, 0.96);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar-transparent {
  padding-top: 15px;
  background-color: rgba(0, 0, 0, 0);
}
.navbar-transparent .navbar-brand,
[class*="navbar-ct"] .navbar-brand {
  color: #ffffff;
  opacity: 0.95;
}
.navbar-transparent .navbar-brand:hover,
.navbar-transparent .navbar-brand:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #5e5e5e;
}
.navbar-transparent .nav .caret {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}
.navbar-transparent .navbar-nav > li > a,
[class*="navbar-ct"] .navbar-nav > li > a {
  color: #ffffff;
  border-color: #ffffff;
  text-align: center;
}
.navbar-transparent .navbar-nav > li > a {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
}

.navbar-transparent .navbar-brand:hover,
.navbar-transparent .navbar-brand:focus,
[class*="navbar-ct"] .navbar-brand:hover,
[class*="navbar-ct"] .navbar-brand:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  background-color: rgba(43, 43, 43, 0.1);
  border-radius: 4px;
  color: #555555;
  opacity: 1;
}
.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #2c93ff;
  border-top-color: #2c93ff;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: rgba(255, 255, 255, 0);
  color: #2c93ff;
}
.navbar-transparent .navbar-nav > .active > a,
.navbar-transparent .navbar-nav > .active > a:hover,
.navbar-transparent .navbar-nav > .active > a:focus,
.navbar-transparent .navbar-nav > li > a:hover,
.navbar-transparent .navbar-nav > li > a:focus,
[class*="navbar-ct"] .navbar-nav > .active > a,
[class*="navbar-ct"] .navbar-nav > .active > a:hover,
[class*="navbar-ct"] .navbar-nav > .active > a:focus,
[class*="navbar-ct"] .navbar-nav > li > a:hover,
[class*="navbar-ct"] .navbar-nav > li > a:focus {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  color: #ffffff;
  opacity: 1;
}
.navbar-transparent .navbar-nav > .dropdown > a .caret,
[class*="navbar-ct"] .navbar-nav > .dropdown > a .caret {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}
.navbar-transparent .navbar-nav > .dropdown > a:hover .caret,
.navbar-transparent .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #ffffff;
  border-top-color: #ffffff;
}
.navbar-transparent .navbar-nav > .open > a,
.navbar-transparent .navbar-nav > .open > a:hover,
.navbar-transparent .navbar-nav > .open > a:focus {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
  opacity: 1;
}
.navbar .label {
  background-color: #ff4c40;
  position: absolute;
  right: -10px;
  top: 0px;
  font-size: 10px;
  font-family: "Helvetica Neue";
  text-shadow: none;
}
[class*="navbar-ct"] .label {
  background-color: #ffffff;
  color: #ff4c40;
}
.navbar-default:not(.navbar-transparent) .btn-default:hover {
  color: #2c93ff;
  border-color: #2c93ff;
}
.navbar-transparent .btn-default,
[class*="navbar-ct"] .btn-default {
  color: #ffffff;
  border-color: #ffffff;
}
[class*="navbar-ct"] .btn-default.btn-fill {
  color: #777777;
  background-color: #ffffff;
  opacity: 0.9;
}
[class*="navbar-ct"] .btn-default.btn-fill:hover,
[class*="navbar-ct"] .btn-default.btn-fill:focus,
[class*="navbar-ct"] .btn-default.btn-fill:active,
[class*="navbar-ct"] .btn-default.btn-fill.active,
[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #ffffff;
  opacity: 1;
}
.navbar-form {
  box-shadow: none;
}
.navbar-form .form-control {
  background-color: rgba(244, 244, 244, 0);
  border-radius: 0;
  border: 0;
  height: 22px;
  padding: 0;
  font-size: 20px;
  line-height: 20px;
  color: #777777;
}
.navbar-transparent .navbar-form .form-control,
[class*="navbar-ct"] .navbar-form .form-control {
  color: #ffffff;
  border: 0;
  /*     border-bottom: 1px solid rgba(255,255,255,.6); */
}
.navbar:not(.navbar-transparent).navbar-ct-blue {
  background-color: #34acdc;
  background-color: rgba(52, 172, 220, 0.98);
}
.navbar:not(.navbar-transparent).navbar-ct-azzure {
  background-color: #5bcaff;
  background-color: rgba(91, 203, 255, 0.98);
}
.navbar:not(.navbar-transparent).navbar-ct-green {
  background-color: #4cd964;
  background-color: rgba(76, 217, 98, 0.98);
}
.navbar:not(.navbar-transparent).navbar-ct-orange {
  background-color: #ff9500;
  background-color: rgba(255, 152, 0, 0.97);
}
.navbar:not(.navbar-transparent).navbar-ct-red {
  background-color: #ff4c40;
  background-color: rgba(255, 76, 64, 0.98);
}

.dropdown-menu > li > a {
  padding: 8px 20px;
  color: #333333;
}
.dropdown-menu > li:first-child > a {
  border-radius: 10px 10px 0 0;
}
.dropdown-menu > li:last-child > a {
  border-bottom: 0 none;
  border-radius: 0 0 10px 10px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #f5f5f5;
  color: #333333;
  opacity: 1;
  text-decoration: none;
}
.navbar-toggle {
  margin-bottom: 27px;
  margin-top: 27px;
  border: 0;
}
.navbar-toggle .icon-bar {
  background-color: #ffffff;
}
.navbar-collapse,
.navbar-form {
  border-color: rgba(0, 0, 0, 0);
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: rgba(0, 0, 0, 0);
}
.navbar-brand-logo {
  padding: 0;
}
.navbar-brand-logo .logo {
  border: 1px solid #333333;
  border-radius: 50%;
  float: left;
  overflow: hidden;
  width: 60px;
}
.navbar .navbar-brand-logo .brand {
  color: #ffffff;
  float: left;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 10px;
  width: 60px;
}
.navbar-default .navbar-brand-logo .brand {
  color: #555;
}
@media (min-width: 768px) {
  .navbar-form {
    margin-bottom: 31px;
    margin-top: 31px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .navbar-search-form {
    display: none;
  }

  .navbar-nav > li > .dropdown-menu {
    display: block;
    padding: 0;
    z-index: 9000;
    position: absolute;
    -webkit-border-radius: 10px;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 0;
    -ms-filter: "alpha(opacity=0)";
    -webkit-filter: alpha(opacity=0);
    -moz-filter: alpha(opacity=0);
    -ms-filter: alpha(opacity=0);
    -o-filter: alpha(opacity=0);
    filter: alpha(opacity=0);
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  }

  .navbar-nav > li.open > .dropdown-menu {
    -webkit-transform-origin: 29px -50px;
    -moz-transform-origin: 29px -50px;
    -o-transform-origin: 29px -50px;
    -ms-transform-origin: 29px -50px;
    transform-origin: 29px -50px;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    -o-filter: none;
    filter: none;
  }

  /* .dropdown-menu:before {
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: -11px;
  }

  .dropdown-menu:after {
    border-bottom: 11px solid #ffffff;
    border-left: 11px solid rgba(0, 0, 0, 0);
    border-right: 11px solid rgba(0, 0, 0, 0);
    content: "";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: -10px;
  } */
}

@media (max-width: 768px) {
  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .navbar-transparent .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-transparent .navbar-nav .open .dropdown-menu > li > a:focus,
  .navbar-transparent .dropdown-menu > li > a:hover,
  .navbar-transparent .dropdown-menu > li > a:focus,
  [class*="navbar-ct"] .dropdown-menu > li > a:hover,
  [class*="navbar-ct"] .dropdown-menu > li > a:focus {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0);
    opacity: 1;
  }
  .navbar-transparent .navbar-nav .open .dropdown-menu > li > a,
  [class*="navbar-ct"] .dropdown-menu > li > a {
    color: #ffffff;
    opacity: 0.8;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: rgba(0, 0, 0, 0);
    border-color: #428bca;
  }
  .navbar-collapse {
    text-align: center;
  }
  .navbar-collapse .navbar-form {
    width: 170px;
    margin: 0 auto;
  }
}

.blurred-container {
  position: relative;
  width: 100%;
  height: 540px;
  top: 0;
  left: 0;
}
.blurred-container > .img-src {
  position: fixed;
  width: 100%;
  height: 540px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.blur {
  opacity: 0;
}

@media (max-width: 991px) {
  .img-src {
    /*
 background-size: 100%;
        background-position: top;
*/
  }
}
.main {
  background-color: #ffffff;
  position: relative;
}
h4 {
  line-height: 1.4em;
}
.space-200 {
  height: 200px;
}
